import React from "react";

const ButtonJoinNow = ({text, classContainer="",onClick, classButton=""}) => {
  return (
    <div className={`rounded-xl border border-solid border-[#ffffff1a] p-[6px] ${classContainer}`} onClick={onClick} >
      <a >
        <button className={`${classButton ? classButton : "rounded-[10px] bg-gradient-to-r from-[#AD98FF] to-[#612DFE] px-4 text-[#ffffff] w-[100px] lg:w-[200px] h-[32px] lg:h-[40px] text-[12px] lg:text-[16px]"} `}>{text}</button>
      </a>
    </div>
  );
};

export default ButtonJoinNow;
