import Footer from "./generals/Footer";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import Navbar from "./generals/Navbar";


const Layout = () => {
  return (
    <div className="layout min-h-screen flex flex-col justify-between">
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />

          <Route
            path="*"
            element={
              <h2 className="text-center" style={{ color: "#001848" }}>
                Page not found
              </h2>
            }
          />
        </Routes>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;