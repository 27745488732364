import React from "react";
import selfCustodyImg from "../../assets/imgs/selfCustody.png";
import manyImg from "../../assets/imgs/manyMarkets.png";
import rewardsImg from "../../assets/imgs/rewards.png";
import fastImg from "../../assets/imgs/fastOnboarding.png";
import governanceImg from "../../assets/imgs/governance.png";
import leadershipImg from "../../assets/imgs/leadershipPrograms.png";
import { useTranslation } from "react-i18next";

const Starking = () => {
  const { t } = useTranslation();

  const listInfo = [
    {
      title: t("Self custody"),
      description: t("You, and only you, keep custody of your funds while trading"),
      img: selfCustodyImg,
    },
    {
      title: t("Many markets"),
      description: t("Leverage web3 technology to streamline operations and expand your business across the globe"),
      img: manyImg,
    },
    {
      title: t("Rewards"),
      description: t("100% of your rewards are claimable at all times or you may decide to stake them in the program"),
      img: rewardsImg,
    },
    {
      title: t("Fast onboarding"),
      description: t("Log in with your existing crypto wallet and start trading in minutes"),
      img: fastImg,
    },
    {
      title: t("Governance"),
      description: t("Control, decision-making and governance are distributed across all participants in the network, with no central authority"),
      img: governanceImg,
    },
    {
      title: t("Leadership programs"),
      description: t("We offer a variety of components aimed at developing essential leadership skills and qualities"),
      img: leadershipImg,
    },
  ];

  return (
    <div className="py-[40px] lg:py-[60px] text-white">
      <div className="text-center lg:text-start">
        <h1 className="text-[24px]">{t("Staking, the way it should be")}</h1>
        <p className="text-[16px] my-3 montserrat-regular">{t("The best of DeFi meets a professional and rewardable staking experience")}</p>
        <div className="bg-gradient-to-r from-[#ffffff33] to-[#ffffff00] w-full h-[2px]"></div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 mt-[40px] lg:mt-[60px]">
        {listInfo.map((info, index) => (
          <div key={index} className="flex items-center mt-0 lg:mt-6 mb-8 lg:mb-6 montserrat-regular">
            <div className="w-[60px] h-[60px]">
              <img src={info.img} alt="img" className="w-full h-full" />
            </div>
            <div className="ml-4 w-9/12">
              <h1 className="text-[20px] lg:text-[24px] font-bold mb-4">{info.title}</h1>
              <p className="text-[14px]">{info.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Starking;
