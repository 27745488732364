import React from "react";
import videoDefily from "../../assets/video/defilyVideo.mp4";
import ButtonJoinNow from "../generals/ButtonJoinNow";
import { useTranslation } from "react-i18next";

const getReferral = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get('referral') || '';
};

const handleJoinNowClick = () => {
  const referral = getReferral();
  const url = `https://dapp.defily.ai?referral=${referral}`;
  window.location.href = url;
};

const AreYouReady = () => {
  const { t } = useTranslation();

  return (
    <div className="px-6 lg:px-[60px] py-[40px] lg:py-[60px] bg-gradient-to-b from-[#AD98FF] to-[#612DFE] text-white flex flex-col lg:flex-row justify-center">
      <div className="mr-0 lg:mr-8 w-full lg:w-2/5 flex flex-col justify-center">
        <h1 className="text-[28px] lg:text-[36px] mb-[18px] lg:mb-6 text-center lg:text-start">{t("Are you ready to start generating passive income")}?</h1>
        <p className="text-[14px] lg:text-[16px] py-2 px-4 rounded-2xl bg-[#ffffff1a] montserrat-regular w-[350px] lg:w-[370px] text-center mx-auto lg:mx-0">{t("Watch this video to learn more about us")}!</p>

        <div className="mt-[18px] lg:mt-[40px] mx-auto lg:mx-0 flex justify-start">
            <ButtonJoinNow text={t("Join Now")} classButton="bg-white text-[#7A2FF4] rounded-[10px] px-4 min-w-[200px] h-[40px] text-[16px] hover:bg-[#7A2FF4] hover:text-white" onClick={handleJoinNowClick} classContainer="border border-solid border-[#ffffff99]"/>   
        </div>
      </div>

      <div className="container-video mt-6 lg:mt-0 mx-auto lg:mx-0">
        <video width="620" height="350" controls>
          <source src={videoDefily} type="video/mp4" />
          <source src="mov_bbb.ogg" type="video/ogg" />
          Your browser does not support HTML video.
        </video>
      </div>
    </div>
  );
};

export default AreYouReady;
