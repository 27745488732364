import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logo from "../../assets/imgs/Logo.png";
import iconTelegram from "../../assets/icons/Telegram.svg";
import iconTwitter from "../../assets/icons/X.svg";
import iconFacebook from "../../assets/icons/logo-facebook-footer.svg";
import iconInstagram from "../../assets/icons/logo-instagram-footer.svg";
import iconLinkedin from "../../assets/icons/LinkedIn.svg";
import iconTiktok from "../../assets/icons/tiktok.svg";
import iconYoutube from "../../assets/icons/YouTube.svg";

const Footer = () => {
  const { t } = useTranslation();

  const listRedes = [
    {
      name: "telegram",
      img: iconTelegram,
      link: "https://t.me/defilyai",
    },
    {
      name: "instagram",
      img: iconInstagram,
      link: "https://www.instagram.com/defily.ai",
    },
    {
      name: "facebook",
      img: iconFacebook,
      link: "https://www.facebook.com/share/RD77vK1C4jpcJs49/?mibextid=LQQJ4d",
    },
    {
      name: "twitter",
      img: iconTwitter,
      link: "https://x.com/defilyai",
    },
    {
      name: "linkedin",
      img: iconLinkedin,
      link: "https://www.linkedin.com/company/defily-ai/",
    },
    {
      name: "tiktok",
      img: iconTiktok,
      link: "https://www.tiktok.com/@defily.ai",
    },
    {
      name: "youtube",
      img: iconYoutube,
      link: "https://youtube.com/@defily?si=1KoT0A27gZBmRH8f",
    },
  ];

  return (
    <div className={`footer bg-[#1F1159] px-6 lg:px-[60px] pt-[40px] lg:pt-[60px] pb-[20px]`}>
      <div className="container-up pb-[20px] border-b border-solid border-[#ffffff1a] mt-0 lg:mt-6 text-white text-[16px]">
        <div className="container-1 mb-9 lg:mb-[40px] grid grid-cols-1 lg:grid-cols-3">
          <div className="container-contactanos text-center lg:text-start">
            <h2 className="font-bold ">{t("Contact Us")}</h2>
            <a href="mailto:hello@defily.io" className="montserrat-regular">info@defily.ai</a>
          </div>
          <div className="container-nosotros flex justify-center ">
            <div className="container-centrado mt-9 lg:mt-0">
              <h2 className="font-bold text-center lg:text-start">{t("About Us")}</h2>
              <Link to={"/terminosYCondiciones"} target="_blank" className="montserrat-regular">
                {t("Terms and Conditions")}
              </Link>
            </div>
          </div>
          <div className="container-img-logo hidden lg:flex justify-end">
            <img src={logo} alt="" />
          </div>
        </div>
        <div className="container-2 grid grid-cols-1 lg:grid-cols-3">
          <div className="container-soporte mb-9 lg:mb-0 text-center lg:text-start">
            <h2 className="font-bold">{t("Support")}</h2>
            <a href="mailto:support@defily.ai" className="montserrat-regular">support@defily.ai</a>
          </div>
          <div className="container-redes ">
            <div className="container-center flex items-center justify-center">
              {listRedes.map((redSocial, index) => {
                return (
                  <div className="container-img-redSocial mr-4 last:mr-0" key={index}>
                    <a href={redSocial.link} target="_blank" rel="noreferrer">
                      <img src={redSocial.img} alt={redSocial.name} />
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="container-img-logo flex justify-center mt-8 lg:hidden">
            <img src={logo} alt="" />
          </div>
        </div>
      </div>
      <div className="container-down pt-[20px] montserrat-regular">
        <p className="text-center text-[14px] text-white">© 2024. Defily. {t("All rights reserved")}.</p>
      </div>
    </div>
  );
};

export default Footer;
