import React from "react";
import Logo from "../../assets/imgs/Logo.png";
import { Link } from "react-router-dom";
import { Link as LinkScroll } from "react-scroll";
import { useTranslation } from "react-i18next";
import ButtonJoinNow from "./ButtonJoinNow";
import ContainerLanguage from "./ContainerLanguage";

const Navbar = () => {
  const { t } = useTranslation();

  const getReferral = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('referral') || '';
  };

  const handleJoinNowClick = () => {
    const referral = getReferral();
    const url = `https://dapp.defily.ai?referral=${referral}`;
    window.location.href = url;
  };

  return (
    <div
      className={`navbar bg-[#1F1159] border-b border-solid border-[#ffffff1a] px-6 lg:px-[60px] py-2 lg:py-[20px] flex items-center justify-between fixed top-0 z-50 w-full`}
    >
      <div className="container-logo flex">
        <Link to={"/"}>
          <img className="img-logo w-[85px] h-[22px] lg:w-[160px] lg:h-[40px]" src={Logo} alt="Logo" />
        </Link>
        <div className="show lg:hidden text-white ml-2">
          <ContainerLanguage />
        </div>
      </div>

      <ul className=" justify-between items-center text-white text-[16px] gap-x-[48px] hidden lg:flex">
        <Link>
          <p className="">{t("How to Join")}</p>
        </Link>

        <LinkScroll to={"#features"} spy={true} smooth={true} offset={-92} duration={500}>
          <p className="cursor-pointer">{t("Features")}</p>
        </LinkScroll>
        <LinkScroll to={"#frequently"} spy={true} smooth={true} offset={-5} duration={500}>
          <p className="cursor-pointer">{t("FAQ")}</p>
        </LinkScroll>

        <ContainerLanguage />
      </ul>

      <ButtonJoinNow text={t("Join Now")} onClick={handleJoinNowClick} />
    </div>
  );
};

export default Navbar;
