import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const FrequentlyAsked = () => {
  const { t } = useTranslation();

  const [itemsOpenAccordion, setItemsOpenAccordion] = useState([]);

  const openOrCloseAccordion = (index) => {
    if (itemsOpenAccordion.includes(index)) {
      setItemsOpenAccordion(itemsOpenAccordion.filter((i) => i !== index));
    } else {
      setItemsOpenAccordion([...itemsOpenAccordion, index]);
    }
  };

  return (
    <div className="text-white px-[24px] lg:px-[60px] py-[40px] lg:py-[80px] flex flex-col lg:flex-row justify-between" id="#frequently">
      <h1 className="text-[32px] lg:text-[40px] w-full lg:w-2/6 text-center lg:text-start mb-8  lg:mb-0">{t("Frequently Asked Questions")}</h1>

      <div className="w-full lg:w-3/5 montserrat-regular">
        <h2>
          <button
            type="button"
            className={`flex items-center justify-between w-full px-1 lg:px-5 pt-5 font-medium rtl:text-right ${
              itemsOpenAccordion.includes(1) ? "pb-0" : "border-b border-solid border-[#ffffff1a] pb-5"
            } `}
            onClick={() => openOrCloseAccordion(1)}
          >
            <span className="text-[16px] font-bold">{t("What is Defily")}?</span>
            <svg
              data-accordion-icon
              className="w-3 h-3 rotate-180 shrink-0"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
            </svg>
          </button>
        </h2>
        <div className={itemsOpenAccordion.includes(1) ? "" : "hidden"}>
          <div className="p-5 border-b border-solid border-[#ffffff1a] text-[14px] text-justify">
            <p className="mb-2">
              {t("Defily is a Web3 project featuring a developed DAO and DAPP")}.
            </p>
            <p className="mb-2  ">
              {t("In the Defily staking platform")}.
            </p>
            <p className="  ">
              {t("Defily empowers its members to participate in a decentralized global network")}.
            </p>
          </div>
        </div>
        <h2>
          <button
            type="button"
            className={`flex items-center justify-between w-full px-1 lg:px-5 pt-5 font-medium rtl:text-right ${
              itemsOpenAccordion.includes(2) ? "pb-0" : "border-b border-solid border-[#ffffff1a] pb-5"
            } `}
            onClick={() => openOrCloseAccordion(2)}
          >
            <span className="text-[16px] font-bold">{t("How does Defily work")}?</span>
            <svg
              data-accordion-icon
              className="w-3 h-3 rotate-180 shrink-0"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
            </svg>
          </button>
        </h2>
        <div className={itemsOpenAccordion.includes(2) ? "" : "hidden"}>
          <div className="p-5 border-b border-solid border-[#ffffff1a] text-[14px] text-justify">
            <p className="">
              {t("Staking crypto assets on the Defily platform is straightforward compared to traditional methods")}.
            </p>
          </div>
        </div>
        <h2>
          <button
            type="button"
            className={`flex items-center justify-between w-full px-1 lg:px-5 pt-5 font-medium rtl:text-right ${
              itemsOpenAccordion.includes(3) ? "pb-0" : "border-b border-solid border-[#ffffff1a] pb-5"
            } `}
            onClick={() => openOrCloseAccordion(3)}
          >
            <span className="text-[16px] font-bold">{t("Why Defily")}?</span>
            <svg
              data-accordion-icon
              className="w-3 h-3 rotate-180 shrink-0"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
            </svg>
          </button>
        </h2>
        <div className={itemsOpenAccordion.includes(3) ? "" : "hidden"}>
          <div className="p-5 border-b border-solid border-[#ffffff1a] text-[14px] text-justify">
            <p className="mb-2  ">{t("Defily staking offers several compelling reasons why users may choose to participate")}</p>

            <ul className="ps-5 list-decimal">
              <li>
                {t("Ease of Use")}.
              </li>
              <li>
                {t("Transparency and Security")}.
              </li>
              <li>
                {t("Profit Opportunities")}.
              </li>
              <li>
                {t("Control Over Assets")}.
              </li>
              <li>
                {t("Community and Affiliate Benefits")}.
              </li>
              <li>
                {t("Innovation in DeFi")}.
              </li>
              <li>
                {t("Long-Term Growth")}.
              </li>
            </ul>

            <p className="mt-2">
              {t("Overall Defily staking appeals to users seeking a modern")}.
            </p>
          </div>
        </div>
        <h2>
          <button
            type="button"
            className={`flex items-center justify-between w-full px-1 lg:px-5 pt-5 font-medium rtl:text-right ${
              itemsOpenAccordion.includes(4) ? "pb-0" : "border-b border-solid border-[#ffffff1a] pb-5"
            } `}
            onClick={() => openOrCloseAccordion(4)}
          >
            <span className="text-[16px] font-bold text-start">{t("How is my staked crypto secured")}?</span>
            <svg
              data-accordion-icon
              className="w-3 h-3 rotate-180 shrink-0"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
            </svg>
          </button>
        </h2>
        <div className={itemsOpenAccordion.includes(4) ? "" : "hidden"}>
          <div className="p-5 border-b border-solid border-[#ffffff1a] text-[14px] text-justify">
            <p className="mb-2  ">{t("In the Defily staking platform, your crypto assets are secured through several key mechanisms")}:</p>

            <ul className="ps-5 list-decimal">
              <li>
                {t("Decentralized Architecture")}.
              </li>
              <li>
                {t("Smart Contract Security")}.
              </li>
              <li>
                {t("User Control of Private Keys")}.
              </li>
              <li>
                {t("Audits and Code Reviews")}.
              </li>
              <li>
                {t("Community Governance and Reputation")}.
              </li>
              <li>
                {t("Continuous Improvement")}.
              </li>
            </ul>

            <p className="mt-2">
              {t("Overall the combination of decentralized architecture")}.
            </p>
          </div>
        </div>
        <h2>
          <button
            type="button"
            className={`flex items-center justify-between w-full px-1 lg:px-5 pt-5 font-medium rtl:text-right ${
              itemsOpenAccordion.includes(5) ? "pb-0" : "pb-5"
            } `}
            onClick={() => openOrCloseAccordion(5)}
          >
            <span className="text-[16px] font-bold text-start">{t("Can I a build a global team and be rewarded")}?</span>
            <svg
              data-accordion-icon
              className="w-3 h-3 rotate-180 shrink-0"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
            </svg>
          </button>
        </h2>
        <div className={itemsOpenAccordion.includes(5) ? "" : "hidden"}>
          <div className="p-5 text-[14px] text-justify">
            <p className="mb-2">
              {t("Building a team and earning passive and active rewards from the affiliate program of Defily involves several steps")}:
            </p>

            <ul className="ps-5 list-decimal">
              <li>
                {t("Joining the Affiliate Program")}.
              </li>
              <li>
                {t("Understanding the Program")}.
              </li>
              <li>
                {t("Sharing Your Affiliate Link")}.
              </li>
              <li>
                {t("Building Your Team")}.
              </li>
              <li>
                {t("Earning Passive Rewards")}.
              </li>
              <li>
                {t("Earning Active Rewards")}.
              </li>
              <li>
                {t("Engaging and Supporting Your Team")}.
              </li>
              <li>
                {t("Monitoring and Optimizing")}.
              </li>
            </ul>

            <p className="mt-2  ">
              {t("By effectively building and nurturing your team")}.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrequentlyAsked;
