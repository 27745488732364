import React from "react";
import coheteImg from "../../assets/imgs/becomeADefily.png";
import joinImg from "../../assets/icons/LogoCorto.svg";
import chooseImg from "../../assets/icons/choose.svg";
import withdrawImg from "../../assets/icons/withdraw.svg";
import referImg from "../../assets/icons/referYourFriends.svg";
import { useTranslation } from "react-i18next";

const BecomeDefily = () => {
  const { t } = useTranslation();

  const listInfo = [
    {
      title: t("Join Defily"),
      description:
        t("Earn rewards and build your entrepreneurial career through a decentralized system and a proven staking platform, where you control your assets"),
      img: joinImg,
      step: `${t("Step")} 1`,
    },
    {
      title: t("Choose your plan"),
      description: t("Pay As You GO or a yearly Membership"),
      img: chooseImg,
      step: `${t("Step")} 2`,
    },
    {
      title: t("Withdraw your profits"),
      description: t("Claim your rewards to your connected wallet anytime"),
      img: withdrawImg,
      step: `${t("Step")} 3`,
    },
    {
      title: t("Refer your friends"),
      description: t("Build a community once and earn rewards multiple times"),
      img: referImg,
      step: `${t("Step")} 4`,
    },
  ];

  return (
    <div className="pb-[40px] lg:pb-[80px] pt-0 lg:pt-[60px] text-white">
      <h1 className="text-[32px] lg:text-[48px] mb-[40px] lg:mb-[60px] border-t-[2px] border-solid border-[#7A2FF4] text-center w-full lg:w-[485px] mx-auto">{t("Become a Defily Member Now")}</h1>

      <div className="flex flex-col lg:flex-row justify-center items-center">
        <div>
          {listInfo.map((item, index) => (
            <div
              key={index}
              className="flex items-center montserrat-regular py-[20px] lg:py-8 px-4 lg:px-7 rounded-2xl bg-gradient-to-b from-[#ffffff1a] to-[#ffffff00] mb-9 last:mb-0 relative"
            >
              <div className=" pr-4">
                <p className="text-[14px] text-[#612DFE] font-bold rounded-[100px] border border-solid border-[#ffffff1a] px-[12px] py-[1px] absolute -top-4">
                  {item.step}
                </p>
                <img src={item.img} alt="" />
              </div>
              <div className="w-5/6 pl-4 border-l border-solid border-[#ffffff1a]">
                <h1 className="text-[18px] lg:text-[24px] mb-4">{item.title}</h1>
                <p className="text-[14px]">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="ml-0 lg:ml-[60px] mt-[20px] lg:mt-0">
          <img src={coheteImg} alt="cohete" className="w-[240px] lg:w-full h-[345px] lg:h-auto"/>
        </div>
      </div>
    </div>
  );
};

export default BecomeDefily;
