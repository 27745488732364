import React from "react";
import laptopImg from "../../assets/imgs/laptopTlf.png";
import ButtonJoinNow from "../generals/ButtonJoinNow";
import { useTranslation } from "react-i18next";

const GenerateStaking = () => {
  const { t } = useTranslation();


  const getReferral = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('referral') || '';
  };
  
  const handleJoinNowClick = () => {
    const referral = getReferral();
    const url = `https://dapp.defily.ai?referral=${referral}`;
    window.location.href = url;
  };

  return (
    <div className="flex justify-center items-center pt-[110px] lg:pt-[220px] pb-12 text-white">
      <div className="w-full lg:w-2/5 mr-0 lg:mr-[60px] text-center lg:text-start">
        <h1 className="text-[24px] lg:text-[28px] mb-6">
          {t("Generate staking")} <span className="text-[#9350FF]">{t("profits")}</span> {t("with your cryptos, on a")}{" "}
          <span className="text-[#9350FF]">{t("solid")}</span> {t("and")} <span className="text-[#9350FF]">{t("innovative")}</span>{" "}
          {t("decentralised platform")}.
        </h1>
        <div className="block lg:hidden mb-6">
          <img src={laptopImg} alt="laptop" className="mx-auto" />
        </div>
        <div className="bg-gradient-to-r from-[#ffffff33] to-[#ffffff00] w-full h-[2px]"></div>
        <p className="text-[16px] montserrat-regular my-6">
          {t("We combine elements of DeFi such as staking and decentralized access to your crypto assets")}
        </p>
        <div className="flex justify-start">
          <ButtonJoinNow
            text={t("Join Now")}
            onClick={handleJoinNowClick}
            classContainer="rounded-xl border border-solid border-[#ffffff1a] p-[6px] mx-auto lg:mx-0"
            classButton="rounded-[10px] bg-gradient-to-r from-[#AD98FF] to-[#612DFE] px-4 text-[#ffffff] w-[200px] h-[40px] text-[16px]"
          />
        </div>
      </div>
      <div className="hidden lg:block">
        <img src={laptopImg} alt="laptop" />
      </div>
    </div>
  );
};

export default GenerateStaking;
