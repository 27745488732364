import React from "react";
import { useTranslation } from "react-i18next";

const TableMarket = () => {
  const {t} = useTranslation();

  return (
    <div className="px-[24px] lg:px-[40px] py-[20px] lg:py-[60px] rounded-3xl border border-solid border-[#ffffff1a] bg-gradient-to-r from-[#ffffff1a] to-[#ffffff00] text-white flex flex-col lg:flex-row items-center justify-center gap-x-[80px]">
      <div className="text-center">
        <p className="text-[24px] lg:text-[28px] mt-0 lg:mt-4">68</p>
        <p className="text-[16px] montserrat-regular">{t("Markets")}</p>
      </div>
      <div className="text-center">
        <p className="text-[24px] lg:text-[28px] mt-4">$492,938</p>
        <p className="text-[16px] montserrat-regular">{t("Todays Volume")}</p>
      </div>
      <div className="text-center">
        <p className="text-[24px] lg:text-[28px] mt-4">323,192</p>
        <p className="text-[16px] montserrat-regular">{t("Todays trades")}</p>
      </div>
      <div className="text-center">
        <p className="text-[24px] lg:text-[28px] mt-4">$150,232,808</p>
        <p className="text-[16px] montserrat-regular">{t("Profits realized")}</p>
      </div>
    </div>
  );
};

export default TableMarket;
